// such file name is needed because the link is hardcoded in the mobile app
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

export default function TermsAndConditions() {
  return (
    <Layout>
      <Seo title="Terms &amp; Conditions" language="en" />
      <section id="terms-and-conditions">
        <div className="layout-space">
          <div>
            <div>
              <h1>Terms and Conditions</h1>
              <p>Updated October 1, 2019</p>
            </div>
          </div>
          <div>
            <div>
              <hr />
            </div>
          </div>
          <div>
            <div>
              <p>
                These Terms and Conditions ("Terms", “Agreement”) govern your
                relationship with VoxRec website https://www.voxrec.net and the
                mobile application (together the "Service") operated by DeepSine
                Limited ("us", "we", or "our").
              </p>
              <p>
                The terms “you,” “your,” “yourself” shall also include your
                employees, agents, business representatives and any other
                individuals that you provide access to the Service through your
                Account (as defined below). You are responsible for ensuring
                that all persons who access the Service through your account are
                aware of these Terms and comply with them.
              </p>
              <p>
                Please read these Terms and Conditions carefully before using
                the Service.
              </p>
              <p>
                Your access to and use of the Service is conditioned on your
                acceptance of and compliance with these Terms. These Terms apply
                to all visitors, users and others who access or use the Service.
              </p>

              <h3>Acceptance</h3>

              <p>
                These Terms govern your access and use of the Service. By
                accessing or using the Service, you are indicating your
                acceptance of this Agreement, which thereby becomes a binding
                contract between you and DeepSine Limited. If you disagree with
                any part of the terms then you may not access nor use the
                Service.
              </p>

              <p>
                You represent that you are legally able to accept these Terms,
                and affirm that you are of legal age to form a binding contract.
                DeepSine Limited’s acceptance is expressly conditioned upon your
                assent to all the terms and conditions of this Agreement. The
                Service is not intended for and should not be used by anyone
                under the age of 18. By using the Service, you represent and
                warrant that you meet the foregoing eligibility requirements.
              </p>
              <p>
                DeepSine Limited reserves the right to revise and update these
                Terms from time to time in its sole discretion. All changes are
                effective immediately when posted. Your continued use of the
                Service following the posting of revised Terms means that you
                accept and agree to the changes. You are expected to check this
                page from time to time so you are aware of any changes, as they
                are binding on you.
              </p>

              <h3>Use of the Service</h3>

              <p>
                Our Service uses speech and voice recognition technology to
                transform voice conversations into text. The Service also
                enables you to share conversations and associated transcripts
                with others via services like email or other text
                communications.
              </p>
              <p>
                We will make the Service available to you. You are responsible
                for making all arrangements necessary for you to have access to
                the Service, including high-speed internet.
              </p>
              <h3>Rules of Conduct</h3>

              <p>
                These are the rules of conduct that you must follow when you use
                the Service:
              </p>

              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not use the Service in any way that violates any
                  applicable federal, state, local, or international law or
                  regulation;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not infringe upon or violate our intellectual
                  property rights or the intellectual property rights of others;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not collect information from our Service using an
                  automated software tool or manually on a mass basis;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not use automated means, or gain unauthorized access
                  to our Service or to any account or computer system connected
                  to our Service;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not obtain, or attempt to obtain, unauthorized access
                  to our Service;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not use the Service in any manner that could disable,
                  overburden, damage, or impair our Service;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>You must not circumvent or reverse engineer our Service;</p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not manipulate or forge identifiers in order to
                  disguise the origin of any information sent to our Service or
                  otherwise provided to us;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not impersonate or attempt to impersonate DeepSine
                  Limited, a DeepSine Limited’s employee, another user, or any
                  other person or entity;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not introduce any viruses, trojan horses, worms,
                  logic bombs, or other material which is malicious or
                  technologically harmful;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not spam, phish, pharm, pretext, spider, crawl, or
                  scrape on our Service nor by using our Service;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  You must not engage in any other conduct that interferes with
                  anyone’s use or enjoyment of the Service, or which, as
                  determined by us, may harm DeepSine Limited or users of the
                  Service or expose them to liability.
                </p>
              </div>

              <p>
                You and others that you record are solely responsible for any
                content that becomes part of the Service Personal Data (as
                defined below). If you record through or upload on to the
                Service materials, recordings, videos, or files (“Service
                Personal Data”), you affirm, represent, and warrant that any
                processing of such data by DeepSine Limited will not violate any
                applicable laws or regulations, and that such content shall not,
                and you will not upload, transmit, or contribute anything that:
              </p>

              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Contains any material that is defamatory, obscene, indecent,
                  abusive, offensive, harassing, violent, hateful, inflammatory,
                  or otherwise objectionable;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Promotes sexually explicit pornographic material, violence, or
                  discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Infringes any patent, trademark, trade secret, copyright, or
                  other intellectual property or other rights of any other
                  person;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Divulges another person’s or entity’s confidential or private
                  information;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Violates the legal rights (including the rights of publicity
                  and privacy) of others or contains any material that could
                  give rise to any civil or criminal liability under applicable
                  laws or regulations or that otherwise may be in conflict with
                  these Terms and our Privacy Policy;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Promotes any illegal activity, or advocates, promotes, or
                  assists any unlawful act;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Causes annoyance, inconvenience, or needless anxiety or be
                  likely to upset, embarrass, alarm, or annoy any other person;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Contains any virus, malware, spyware or other harmful content
                  or code, or materials that otherwise violate this Agreement.
                </p>
              </div>

              <p>
                The Service provides a feature that allows you to record
                individual conversations and/or upload recorded conversations.
                The laws regarding the notice and notification requirements of
                such recorded conversations vary by location. It may be an
                offense in your jurisdiction to record other individuals without
                their prior written consent. It is your responsibility to follow
                applicable foreign, federal and local laws when using such
                features, including informing the participants in that their
                voice is being recorded. We absolutely do not condone the use of
                any of our Service for illegal purposes.
              </p>

              <p>
                The use of the Service is done at your own discretion and risk
                and with the agreement that you will be solely responsible for
                Service Personal Data and the consequences of recording,
                submitting and processing Service Personal Data on or through
                the Service.
              </p>

              <h3>Monitoring and Enforcement</h3>

              <p>
                We may review conduct for compliance purposes. We are not
                responsible for the content of any of your materials,
                recordings, data, information, content, or files (“Service
                Personal Data”) or the way you choose to store or process any
                Service Personal Data on the Service.
              </p>

              <p>
                We reserve the right to investigate and take appropriate legal
                action in our sole discretion against anyone who violates these
                Terms. We have the right to:
              </p>

              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>Remove any Service Personal Data;</p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Take any action with respect to any Service Personal Data that
                  we deem necessary or appropriate in our sole discretion,
                  including if we believe that such Data violates the Terms,
                  infringes any intellectual property right or other right of
                  any person or entity, threatens the personal safety of users
                  or the public, or could create liability for DeepSine Limited;
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Service; or
                </p>
              </div>
              <div>
                <span>
                  <i className="fe fe-check"></i>
                </span>

                <p>
                  Terminate or suspend your access to all or part of the Service
                  for any reason, including without limitation, any violation of
                  these Terms.
                </p>
              </div>

              <p>
                Without limiting the foregoing, we have the right to cooperate
                with any law enforcement authorities or court order requesting
                or directing us to disclose the identity or other information of
                anyone posting any data on or through the Service.
              </p>

              <p>
                YOU WAIVE AND HOLD HARMLESS DEEPSINE Limited AND ITS AFFILIATES,
                LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM
                ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR
                TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES
                OR LAW ENFORCEMENT AUTHORITIES.
              </p>

              <p>
                However, we do not undertake to review any material before it is
                uploaded to or sent to the Service, and cannot ensure prompt
                removal of objectionable material. Accordingly, we assume no
                liability for any action or inaction regarding transmissions,
                communications, or content provided by any user or third party.
                We have no liability or responsibility to anyone for performance
                or nonperformance of the activities described in this section.
              </p>

              <h3>Ownership and Intellectual Property</h3>

              <p>
                The Service, including the website, apps and its entire
                contents, features, and functionality (including, but not
                limited to, all information, software, text, displays, images,
                video, and audio, and the design, selection, and arrangement
                thereof), are owned by DeepSine Limited, its affiliates,
                licensors, or other providers of such material and are protected
                by international copyright, trademark, patent, trade secret, and
                other intellectual property or proprietary rights laws. DeepSine
                Limited, its affiliates and licensors are and shall continue to
                be the sole and exclusive owner of all right, title and interest
                in and to all intellectual property rights associated with the
                Service. You must not modify, copy, reproduce, republish,
                upload, post, transmit, translate, sell, create derivative
                works, exploit, or distribute in any manner or medium (including
                by email or other electronic means) any material from the
                Service unless explicitly authorized in these Terms or by the
                owner(s) of the materials.
              </p>

              <p>
                We grant to customers a non-sublicensable, non-transferable,
                non-exclusive, limited license to use the Service in accordance
                with this Agreement and its intended purposes only. Except for
                allowing you to use the Service as set forth herein, you are not
                receiving any other license or any other rights, including
                intellectual property or other proprietary rights of DeepSine
                Limited. You understand that you have no rights to the Service
                or any other DeepSine Limited property except as indicated in
                these Terms. All of our rights not expressly granted by this
                license are hereby retained.
              </p>

              <p>Customer materials</p>

              <p>
                Customer retains all ownership rights in Service Personal Data.
                However, by transmitting, uploading or posting Service Personal
                Data on or through the Service, you hereby grant DeepSine
                Limited a worldwide, non-exclusive, royalty-free, license to
                access, process, copy, export, and display Service Personal
                Data, only as reasonably necessary (a) to provide, maintain and
                update the Service; (b) to prevent or address service, security,
                support or technical issues; (c) as required by law; and (d) as
                expressly permitted in writing by Customer. Customer represents
                and warrants that it has secured all rights in and to Service
                Personal Data as may be necessary to grant this license.
              </p>

              <p>Feedback</p>

              <p>
                If you send us any feedback or suggestions regarding the
                Service, we may or may not use it. You grant us an unlimited,
                irrevocable, perpetual, sublicensable, transferable,
                royalty-free license to use any such feedback or suggestions for
                any purpose without any obligation or compensation to you, the
                Customer.
              </p>

              <h3>Purchases</h3>

              <p>
                If you wish to purchase any product or service made available
                through the Service ("Purchase"), you may be asked to supply
                certain information relevant to your Purchase including, without
                limitation, your credit card number, the expiration date of your
                credit card, your billing address, and your shipping
                information.
              </p>

              <p>
                You represent and warrant that: (i) you have the legal right to
                use any credit card(s) or other payment method(s) in connection
                with any Purchase; and that (ii) the information you supply to
                us is true, correct and complete.
              </p>

              <p>
                By submitting such information, you grant us the right to
                provide the information to third parties for purposes of
                facilitating the completion of Purchases.
              </p>

              <p>
                We reserve the right to refuse or cancel your order at any time
                for certain reasons including but not limited to: product or
                service availability, errors in the description or price of the
                product or service, error in your order or other reasons.
              </p>

              <p>
                We reserve the right to refuse or cancel your order if fraud or
                unauthorised or illegal transaction is suspected.
              </p>

              <h3>Availability, Errors and Inaccuracies</h3>

              <p>
                We are constantly updating our offerings of products and
                services on the Service. The products or services available on
                our Service may be mispriced, described inaccurately, or
                unavailable, and we may experience delays in updating
                information on the Service and in our advertising on other web
                sites.
              </p>

              <p>
                We cannot and do not guarantee the accuracy or completeness of
                any information, including prices, product images,
                specifications, availability, and services. We reserve the right
                to change or update information and to correct errors,
                inaccuracies, or omissions at any time without prior notice.
              </p>

              <h3>Promotions</h3>

              <p>
                Any contests, sweepstakes or other promotions (collectively,
                "Promotions") made available through the Service may be governed
                by rules that are separate from these Terms. If you participate
                in any Promotions, please review the applicable rules as well as
                our Privacy Policy. If the rules for a Promotion conflict with
                these Terms, the Promotion rules will apply.
              </p>

              <h3>Subscriptions</h3>

              <p>
                Some parts of the Service are billed on a subscription basis
                ("Subscription(s)"). You will be billed in advance on a
                recurring and periodic basis ("Billing Cycle"). Billing cycles
                are set either on a monthly or annual basis, depending on the
                type of subscription plan you select when purchasing a
                Subscription.
              </p>

              <p>
                At the end of each Billing Cycle, your Subscription will
                automatically renew under the exact same conditions unless you
                cancel it or we cancel it. You may cancel your Subscription
                renewal either through your subscription management page or by
                contacting our customer support team.
              </p>

              <p>
                A valid payment method, including credit card or PayPal, is
                required to process the payment for your Subscription. You shall
                provide us with accurate and complete billing information
                including full name, address, state, zip code, telephone number,
                and a valid payment method information. By submitting such
                payment information, you automatically authorize DeepSine
                Limited to charge all Subscription fees incurred through your
                account to any such payment instruments.
              </p>

              <p>
                Should automatic billing fail to occur for any reason, DeepSine
                Limited will issue an electronic invoice indicating that you
                must proceed manually, within a certain deadline date, with the
                full payment corresponding to the billing period as indicated on
                the invoice.
              </p>

              <h3>Free Trial</h3>

              <p>
                We may, at our sole discretion, offer a Subscription with a free
                trial for a limited period of time ("Free Trial").
              </p>

              <p>
                You may be required to enter your billing information in order
                to sign up for the Free Trial.
              </p>

              <p>
                If you do enter your billing information when signing up for the
                Free Trial, you will not be charged by DeepSine Limited until
                the Free Trial has expired. On the last day of the Free Trial
                period, unless you cancelled your Subscription, you will be
                automatically charged the applicable Subscription fees for the
                type of Subscription you have selected.
              </p>

              <p>
                At any time and without notice, we reserve the right to (i)
                modify the terms and conditions of the Free Trial offer, or (ii)
                cancel such Free Trial offer.
              </p>

              <h3>Fee Changes</h3>

              <p>
                We, in our sole discretion and at any time, may modify the
                Subscription fees for the Subscriptions. Any Subscription fee
                change will become effective at the end of the then-current
                Billing Cycle.
              </p>

              <p>
                We will provide you with reasonable prior notice of any change
                in Subscription fees to give you an opportunity to terminate
                your Subscription before such change becomes effective.
              </p>

              <p>
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes your agreement to pay the
                modified Subscription fee amount.
              </p>

              <h3>Refunds</h3>

              <p>
                Certain refund requests for Subscriptions may be considered by
                us on a case-by-case basis and granted at our sole discretion.
              </p>

              <h3>Accounts and Registration</h3>

              <p>
                When you create an account with us, you must provide us with
                information that is true, accurate, complete, and current at all
                times. You are responsible for keeping such information
                up-to-date; this includes your contact information, so that we
                can reliably contact you. The information you submit must
                describe you (you may not impersonate another person or entity),
                and you may not sell, share or otherwise transfer your account
                information. If you provide any information that is untrue or
                inaccurate, not current, or incomplete, or if we suspect that
                your information is untrue or inaccurate, not current, or
                incomplete, DeepSine Ltd may, in its sole discretion, suspend or
                terminate your right to access any material for which
                registration is required. Any personally identifiable
                information supplied hereunder will be subject to the terms of
                the Privacy Policy.
              </p>

              <p>
                You are responsible for safeguarding the password that you use
                to access the Service and for any activities or actions under
                your password, whether your password is with our Service or a
                third-party service. You are responsible for all activity
                occurring when the Service is accessed through your account,
                whether authorized by you or not. We are not liable for any loss
                or damage arising from your failure to protect your password or
                account information.
              </p>

              <p>
                You agree not to disclose your password to any third party. You
                must notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
              </p>

              <p>
                We have the right to disable your account, password, or other
                identifier, whether chosen by you or provided by us, at any
                time, in our sole discretion for any or no reason, including if,
                in our opinion, you have violated any provision of these Terms.
              </p>

              <h3>Links To Other Websites</h3>

              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by DeepSine Limited.
              </p>

              <p>
                We have no control over, and assume no responsibility for, the
                content, privacy policies, or practices of any third-party web
                sites or services. You further acknowledge and agree that
                DeepSine Limited shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                web sites or services.
              </p>

              <p>
                We strongly advise you to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                you visit.
              </p>

              <h3>Termination</h3>

              <p>
                You may stop using our Service at any time. We may terminate or
                suspend your access to or ability to use any and all Service
                immediately, without prior notice or liability, for any reason
                whatsoever. In particular, we may immediately terminate or
                suspend your account if you are in breach of these Terms or if
                your account has been flagged for copyright infringement.
              </p>

              <p>
                Termination of your access to and use of the Service shall not
                relieve you of any obligations arising or accruing prior to such
                termination or limit any liability that you otherwise may have
                to DeepSine Limited or any third party.
              </p>

              <h3>Limitation Of Liability</h3>

              <p>
                To the fullest extent provided by law, in no event shall
                DeepSine Limited, nor its directors, employees, partners,
                agents, suppliers, or affiliates, be liable for any indirect,
                incidental, special, consequential or punitive damages,
                including without limitation, loss of profits, data, use,
                goodwill, or other intangible losses, resulting from (i) your
                access to or use of or inability to access or use the Service;
                (ii) any conduct or content of any third party on the Service;
                (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or
                content, whether based on warranty, contract, tort (including
                negligence) or any other legal theory, whether or not we have
                been informed of the possibility of such damage, and even if a
                remedy set forth herein is found to have failed of its essential
                purpose.
              </p>

              <p>
                In no event shall DeepSine Limited’s total liability for all
                damages under this agreement exceed the lesser of (i) The
                aggregated amounts you paid to DeepSine Ltd during the three
                month period immediately preceding the circumstances giving rise
                to the claim, or (ii) £500.
              </p>

              <p>
                The foregoing does not affect any liability that cannot be
                excluded or limited under applicable law.
              </p>

              <h3>Disclaimer</h3>

              <p>
                Your use of the Service is at your sole risk. The Service is
                provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
                provided without warranties of any kind, whether expressed or
                implied, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose,
                non-infringement or course of performance.
              </p>

              <p>
                Neither DeepSine Limited nor any person associated with us makes
                any warranty or representation with respect to the completeness,
                security, reliability, quality, accuracy, or availability of the
                Service. Without limiting the foregoing, neither DeepSine
                Limited nor anyone associated with us represents or warrants
                that the Service, and any content or products therein will be
                accurate, reliable, error-free, or uninterrupted, that defects
                will be corrected, that our site or the server that makes it
                available are free of viruses or other harmful components, or
                that the Service, and any content or products therein will
                otherwise meet your needs or expectations. to the fullest extent
                provided by law, DeepSine Limited hereby disclaims any and all
                representations, warranties or guarantees of any kind, express,
                implied, statutory or otherwise, including, but not limited to,
                any warranties of merchantability, noninfringement and fitness
                for a particular purpose.
              </p>

              <p>
                DeepSine Limited may alter, suspend, add to, or discontinue the
                Service in whole or in part at any time for any reason, without
                notice or cost. We assume no responsibility for your ability to
                (or any costs or fees associated with your ability to) obtain
                access to the Service. We do not assume any liability for the
                failure to store or maintain any Service Personal Data, user
                communications, account information, or personal settings. The
                Service may become unavailable due to maintenance or malfunction
                of computer equipment, servers, or other reasons.
              </p>

              <p>
                You are solely responsible for any and all acts or omissions
                taken or made in reliance on the Service or the information
                contained therein, including inaccurate or incomplete
                information. You agree that DeepSine Limited has made no
                agreements, representations or warranties other than those
                expressly set forth in these terms.
              </p>

              <h3>Indemnification</h3>

              <p>
                You agree to defend, indemnify, and hold harmless DeepSine
                Limited, its affiliates, licensors, and service providers, and
                its and their respective officers, directors, employees,
                contractors, agents, licensors, suppliers, successors, and
                assigns from and against any claims, liabilities, damages,
                judgments, awards, losses, costs, expenses, or fees (including
                reasonable attorneys’ fees) arising out of or relating to your
                violation of these Terms or your use of the Service, including,
                but not limited to, your Service Personal Data, any use of the
                services, content and products other than as expressly
                authorized in these Terms or your use of any information
                obtained from the Service.
              </p>

              <h3>Governing Law</h3>

              <p>
                These Terms shall be governed and construed in accordance with
                the laws of the United Kingdom, without regard to its conflict
                of law provisions.
              </p>

              <p>
                Our failure to enforce any right or provision of these Terms
                will not be considered a waiver of those rights. If any
                provision of these Terms is held to be invalid or unenforceable
                by a court, the remaining provisions of these Terms will remain
                in effect. These Terms constitute the entire agreement between
                us regarding our Service, and supersede and replace any prior
                agreements we might have between us regarding the Service.
              </p>

              <h3>Changes</h3>

              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will try to provide at least 15 days notice prior to any new
                terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </p>

              <p>
                By continuing to access or use the Service after those revisions
                become effective, you agree to be bound by the revised terms. If
                you do not agree to the new terms, please stop using the
                Service.
              </p>

              <h3>Privacy</h3>

              <p>
                Please see our Privacy Policy. By visiting this Site and
                accessing and using the Service, you consent to our collection
                and use of your personal information as set forth in the Privacy
                Policy.
              </p>

              <h3>Miscellaneous</h3>

              <p>Survival</p>

              <p>
                All provisions of this Agreement that by their nature should
                survive termination, shall survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                limitations of liability, and indemnification.
              </p>

              <p>Severability</p>

              <p>
                No waiver by DeepSine Limited of any term or condition set out
                in these Terms shall be deemed a further or continuing waiver of
                such term or condition or a waiver of any other term or
                condition, and any failure of DeepSine Limited to assert a right
                or provision under these Terms shall not constitute a waiver of
                such right or provision. If any provision of these Terms is held
                by a court or other tribunal of competent jurisdiction to be
                invalid, illegal or unenforceable for any reason, such provision
                shall be eliminated or limited to the minimum extent such that
                the remaining provisions of the Terms will continue in full
                force and effect.
              </p>

              <p>Entire Agreement</p>

              <p>
                These Terms, together with our Privacy Notice, shall constitute
                the entire agreement between you and DeepSine Ltd concerning the
                Service.
              </p>

              <h3>Contact Us</h3>

              <p>
                If you have any questions about these Terms, please contact us
                privacy@voxrec.net
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
